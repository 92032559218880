import { Helmet } from 'react-helmet'

import LayoutHeader from '../../components/LayoutHeader'

const GeneralLayout = (props) => {
  return (
    <div className="relative w-full min-h-full bg-black">
      <Helmet titleTemplate="%s" />
      <div className="flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 w-full justify-center">
        <p className="text-sm leading-6 text-white">
          <a href="https://t.me/heywallet_bot">
            <strong className="font-semibold">🚀 Introducing the new Telegram/X Trading Bot</strong>
            <svg
              viewBox="0 0 2 2"
              className="mx-2 inline h-0.5 w-0.5 fill-current"
              aria-hidden="true"
            >
              <circle cx={1} cy={1} r={1} />
            </svg>
            Join our alpha now&nbsp;
            <span aria-hidden="true">&rarr;</span>
          </a>
        </p>
      </div>
      <div className="flex flex-col w-full items-center overflow-y-auto overflow-x-hidden px-5">
        <LayoutHeader />
        {props.children}
      </div>
    </div>
  )
}

export default GeneralLayout
