import { createElement, useMemo, useState, useEffect, useRef, useContext } from 'react'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import AuthContext from '../../context/AuthContext'
import Loading from '../Loading'

import { SESSION_COOKIE_NAME } from '../../constant'
import { ApiURL } from '../../config'

import styles from './NftList.module.css'

const TokenSendModal = ({ token, chainId, onClose }) => {
  const history = useHistory()
  const { currentUser } = useContext(AuthContext)
  const [cookies] = useCookies([SESSION_COOKIE_NAME])

  const [handle, setHandle] = useState('')
  const ref = useRef(null)

  const onHandleChange = (e) => {
    setHandle(e.target.value)
  }

  const scrollToTop = () => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const handleSend = (e) => {
    e.preventDefault()
    if (!currentUser) {
      alert('Please login to send tokens')
      return
    }

    const params =
      chainId === 'SOL'
        ? {
            mintPublicKey: token.mintPublicKey,
          }
        : {
            contractAddress: token.contractAddress,
            tokenId: token.tokenId,
          }

    fetch(`${ApiURL}actions/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies[SESSION_COOKIE_NAME]}`,
      },
      body: JSON.stringify({
        handle,
        amount: 1,
        ticker: token.mintPublicKey,
        isNft: true,
        chainId,
        ...params,
      }),
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          if (data.txId) {
            history.push(`/transactions/${data.txId}`)
          } else {
            alert('Failed to send')
          }
        })
      } else {
        alert('Failed to send')
      }
    })
  }

  useEffect(() => {
    scrollToTop()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        className={`fixed ${styles.listingModalBg}`}
        onClick={onClose}
        onKeyUp={() => null}
        role="button"
        tabIndex={0}
      ></div>
      <div className={`absolute ${styles.listingModal}`} ref={ref}>
        <div className="bg-[#37373C] px-6 py-6 rounded-xl flex flex-col items-center relative">
          <p className="text-gray-ececec w-full font-bold text-center text-2xl">
            Send a token via Twitter
          </p>
          <div className="flex text-white justify-center space-x-3 items-center mt-6">
            <div>
              <img src={token.imageUrl} alt={token.name} className="h-14 w-14 rounded-md" />
            </div>
            <div>
              <div className="font-bold">{token.name}</div>
            </div>
          </div>
          <div className="w-full mt-6 flex flex-col flex-wrap justify-between">
            <div className={`px-4 py-3 mt-2 flex bg-white rounded-md ${styles.amountInput}`}>
              <input
                className="w-3/4 focus:outline-none"
                placeholder="@handle"
                value={handle}
                onChange={onHandleChange}
              />
            </div>
            <button
              className="w-full rounded-md px-2 py-3 mt-2 font-medium text-white bg-[#209BF0]"
              onClick={handleSend}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const NftContainer = ({ nft, nftMetadata }) => {
  const { currentUser } = useContext(AuthContext)
  const [isLoaded, setIsLoaded] = useState(!!nftMetadata.animation_url)
  const [isSendOpen, setIsSendOpen] = useState(false)

  const { id } = useParams()
  const username = useMemo(() => (id?.[0] === '@' ? id.slice(1) : id), [id])

  const image = useMemo(() => {
    const imageElement = createElement('img', {
      src: nft.imageUrl,
      alt: isLoaded ? nft.name : '', // Don't show the alt text while the image is loading
      className: 'h-full w-full object-contain rounded-lg',
      onLoad: () => setIsLoaded(true),
    })
    return imageElement
  }, [nft.imageUrl, nft.name, isLoaded, setIsLoaded])

  return (
    <>
      <div
        className="relative flex flex-col w-full"
        style={{
          maxWidth: 340,
        }}
      >
        {isLoaded ? null : (
          <Loading
            className="absolute self-center w-14"
            style={{
              left: 'calc(50% - 28px)',
              top: 'calc(50% - 57px)',
            }}
          />
        )}
        <Link
          to={
            nft.compressed ? `/nfts/zip/${nft.mintPublicKey}` : `/nfts/details/${nft.mintPublicKey}`
          }
          className="flex w-full h-full items-center justify-center rounded-lg"
          style={{
            backgroundColor: '#1B1B1B',
            height: 340,
            maxWidth: 340,
          }}
        >
          {nftMetadata.animation_url ? (
            <video
              autoPlay
              loop
              muted
              playsInline
              className="h-full w-full object-contain rounded-lg"
              style={{
                backgroundColor: '#1B1B1B',
              }}
            >
              <source src={nftMetadata.animation_url} type="video/mp4" />
            </video>
          ) : (
            image
          )}
        </Link>
        {currentUser && currentUser.username === username && (
          <div
            className="flex flex-wrap rounded-lg items-center px-4 py-3 justify-between"
            style={{
              backgroundColor: '#1B1B1B',
              minHeight: '3.5rem',
              maxWidth: 340,
            }}
          >
            <p className="text-gray-ececec w-full font-bold">{nft.name}</p>
            <div className="w-full mt-2 flex justify-between space-x-3">
              <button
                className={`w-full rounded-sm px-2 py-1 font-medium ${styles.transferBtn} ${
                  !isLoaded ? 'cursor-default' : ''
                }`}
                onClick={() => {
                  setIsSendOpen(true)
                }}
                disabled={!isLoaded}
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>

      {isSendOpen && (
        <TokenSendModal
          token={nft}
          chainId="SOL"
          onClose={() => {
            setIsSendOpen(false)
          }}
        />
      )}
    </>
  )
}

const EthNftContainer = ({ nft }) => {
  const { currentUser } = useContext(AuthContext)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSendOpen, setIsSendOpen] = useState(false)

  const { id } = useParams()
  const username = useMemo(() => (id?.[0] === '@' ? id.slice(1) : id), [id])

  const imageUrl = nft?.media[0].gateway || nft?.rawMetadata?.image
  const name = nft?.rawMetadata?.name || nft?.title

  const image = useMemo(() => {
    const imageElement = createElement('img', {
      src: imageUrl,
      alt: isLoaded ? name : '', // Don't show the alt text while the image is loading
      className: 'h-full w-full object-contain rounded-lg',
      onLoad: () => setIsLoaded(true),
    })
    return imageElement
  }, [imageUrl, name, isLoaded, setIsLoaded])

  const nftProps = {
    name,
    imageUrl,
    contractAddress: nft.contract?.address,
    tokenId: nft.tokenId,
  }

  return (
    <>
      <div
        className="relative flex flex-col w-full"
        style={{
          maxWidth: 340,
        }}
      >
        {isLoaded ? null : (
          <Loading
            className="absolute self-center w-14"
            style={{
              left: 'calc(50% - 28px)',
              top: 'calc(50% - 57px)',
            }}
          />
        )}
        <div className="flex w-full h-full items-center justify-center rounded-lg">{image}</div>
        {currentUser && currentUser.username === username && (
          <div
            className="flex flex-wrap rounded-lg items-center px-4 py-3 justify-between"
            style={{
              backgroundColor: '#1B1B1B',
              minHeight: '3.5rem',
              maxWidth: 340,
            }}
          >
            <p className="text-gray-ececec w-full font-bold">{name}</p>
            <div className="w-full mt-2 flex justify-between space-x-3">
              <button
                className={` rounded-sm px-2 py-1 font-medium ${styles.transferBtn} ${
                  !isLoaded ? 'cursor-default' : ''
                } w-full`}
                onClick={() => {
                  setIsSendOpen(true)
                }}
                disabled={!isLoaded}
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>
      {isSendOpen && (
        <TokenSendModal
          token={nftProps}
          chainId="MATIC"
          onClose={() => {
            setIsSendOpen(false)
          }}
        />
      )}
    </>
  )
}

const NftList = ({ nfts, nftsMetadata }) => {
  return (
    <>
      <>
        {nfts?.sol?.map((nft) => (
          <NftContainer
            key={`nft-${nft.name}-${nft.mintPublicKey}`}
            nft={nft}
            nftMetadata={nftsMetadata[nft.mintPublicKey] || {}}
          />
        ))}
      </>
      <>
        {nfts?.eth.map((nft) => (
          <EthNftContainer key={`nft-${nft.contractAddress}-${nft.tokenId}`} nft={nft} />
        ))}
      </>
    </>
  )
}

const NftListContainer = ({ nfts, nftsMetadata }) => {
  const LoadingContainer = (
    <div className="flex w-full h-10 justify-center">
      <Loading />
    </div>
  )

  return (
    <div className="fade-focus-in flex w-full">
      {!nfts ? (
        LoadingContainer
      ) : (
        <div
          className="flex w-full pb-4 flex-wrap items-start justify-center md:justify-between"
          style={{
            gap: 20,
          }}
        >
          {nfts?.sol?.length > 0 || nfts?.eth?.length > 0 ? (
            <NftList nfts={nfts} nftsMetadata={nftsMetadata} />
          ) : (
            <p className="w-full pt-4 pb-3 text-center text-44 font-black text-gray-4b4b4b">None</p>
          )}
        </div>
      )}
    </div>
  )
}

export default NftListContainer
